export const PRODUCTS_BY_PAGE = 7;
export const PRODUCTS_SIZES = {
  default: {
    size: 128,
    margin: 20,
  },
} as const;

export const IMAGE_SIZES = {
  size: 48,
  hoverSize: 53,
};
