import { PRODUCTS_SIZES, PRODUCTS_BY_PAGE } from './constants';

/**
 * calculateProductOuterSize returns the width of one product and its margins in pixels in the current viewport.
 * It's needed to know how many pixels the carousel needs to slide on each arrow click.
 */
const calculateProductOuterSize = (windowSize: { width: number }): number =>
  windowSize.width >= 1200
    ? PRODUCTS_SIZES.default.size + PRODUCTS_SIZES.default.margin
    : PRODUCTS_SIZES.default.size + PRODUCTS_SIZES.default.margin;

/**
 * calculateProductsListOuterSize returns the sum of the sizes and margins of the maximum number of products allowed in a row.
 * It's needed to define the width of the carousel container.
 * N.B: The value is only used on desktops when the number of products is greater than the maximum allowed in a row (PRODUCTS_BY_PAGE).
 */
const calculateProductsListOuterSize = (windowSize: {
  width: number;
}): number | null => {
  if (windowSize.width >= 1200) {
    return (
      PRODUCTS_SIZES.default.size * PRODUCTS_BY_PAGE +
      PRODUCTS_SIZES.default.margin * PRODUCTS_BY_PAGE
    );
  }
  if (windowSize.width < 1200 && windowSize.width >= 900) {
    return (
      PRODUCTS_SIZES.default.size * PRODUCTS_BY_PAGE +
      PRODUCTS_SIZES.default.margin * PRODUCTS_BY_PAGE
    );
  }
  return null;
};

const calculateGap = (productSize: { size: number; margin: number }): number =>
  productSize.margin;

export {
  calculateProductOuterSize,
  calculateProductsListOuterSize,
  calculateGap,
};
