import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { css, Theme } from '@emotion/react';
import { CarouselComposer, Headline } from '@sumup/circuit-ui';
import Tagger from '@elbwalker/tagger';

import { PRODUCTS_SIZES, PRODUCTS_BY_PAGE } from './constants';
import {
  calculateProductsListOuterSize,
  calculateProductOuterSize,
  calculateGap,
} from './MultiProductCarouselService';
import Product, { ProductType } from './components/Product';

import { VIEWPORTS } from '~/shared/constants';
import useWindowSize from '~/shared/hooks/use-window-size';
import useViewportName from '~/shared/hooks/use-viewport-name';
import {
  TaggingAction,
  TaggingEntity,
  TaggingTrigger,
} from '~/shared/types/walker';

const CarouselButtonWrapper = styled('div')(
  ({ theme, isNext = false }: { theme?: Theme; isNext?: boolean }) => css`
    width: calc(30px + ${theme.spacings.tera});
    display: flex;
    justify-content: ${isNext ? 'right' : 'left'};
  `,
);

const carouselButtonStyles = () => css`
  flex-shrink: 0;
  background-color: var(--cui-bg-highlight);
  color: var(--cui-fg-normal);
  border: 0;
  :hover {
    background-color: var(--cui-bg-highlight-hovered);
  }
`;

const StyledNextButton = styled(CarouselComposer.NextButton)(
  carouselButtonStyles,
);

const StyledPrevButton = styled(CarouselComposer.PrevButton)(
  carouselButtonStyles,
);

const StyledHeadline = styled(Headline)(
  ({ theme }) => css`
    text-align: center;
    margin-bottom: ${theme.spacings.giga};
  `,
);

const CarouselWrapper = styled(CarouselComposer.Container)(
  `
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-shrink: 0;
  `,
);

// HACK: override default spacing for SA-64164.
// It's intended to be placed between two column layout components.
const Wrapper = styled('div')(
  ({ theme }: { theme: Theme }) => css`
    margin-top: -20px;

    ${theme.mq.untilKilo} {
      margin-top: -10px;
      margin-bottom: 26px;
    }
  `,
);

const ProductsContainerResponsiveStyles = ({
  theme,
  isCarousel,
}: {
  theme: Theme;
  isCarousel?: boolean;
}) => css`
  ${theme.mq.mega} {
    justify-content: ${isCarousel ? 'start' : 'center'};
  }
  ${theme.mq.untilKilo} {
    padding-left: ${theme.spacings.peta};
    padding-right: ${theme.spacings.peta};
    gap: ${calculateGap(PRODUCTS_SIZES.default)}px;
  }
  ${theme.mq.kiloToMega} {
    padding-left: ${theme.spacings.tera};
    padding-right: ${theme.spacings.tera};
    gap: ${calculateGap(PRODUCTS_SIZES.default)}px;
  }
  ${theme.mq.megaToGiga} {
    gap: ${calculateGap(PRODUCTS_SIZES.default)}px;
  }
`;

const ProductsContainer = styled.ul(
  `
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  list-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`,
  ({
    isCarousel,
    step,
    productOuterSize,
    productsListOuterSize,
  }: {
    isCarousel?: boolean;
    step?: number;
    productOuterSize?: number;
    productsListOuterSize?: number;
  }) => ({
    overflowX: isCarousel ? 'hidden' : 'auto',
    width: isCarousel ? `${productsListOuterSize}px` : '100%',
    gap: `${calculateGap(PRODUCTS_SIZES.default)}px`,
    li: {
      ...(isCarousel && {
        transform: `translate3d(${-step * productOuterSize}px, 0, 0)`,
        transition: 'all 0.3s ease-in-out',
      }),
    },
  }),
  ProductsContainerResponsiveStyles,
);

interface Props {
  products: ProductType[];
  headline?: string;
  taggingEntity?: TaggingEntity;
  taggingContext?: { [key: string]: string };
  taggingAction?: TaggingAction;
  taggingTrigger?: TaggingTrigger;
}

function MultiProductCarousel({
  products,
  headline,
  taggingEntity,
  taggingAction,
  taggingTrigger,
  taggingContext = {},
}: Props) {
  const windowSize = useWindowSize();
  const viewportName = useViewportName();
  const total = products?.length || 0;
  const [step, setStep] = React.useState(0);
  const slideBack = () =>
    setStep(step === 0 ? total - PRODUCTS_BY_PAGE : step - 1);
  const slideForward = () =>
    setStep(step === total - PRODUCTS_BY_PAGE ? 0 : step + 1);
  const productOuterSize = calculateProductOuterSize(windowSize);
  const productsListOuterSize = calculateProductsListOuterSize(windowSize);
  const isCarousel =
    total > PRODUCTS_BY_PAGE && viewportName === VIEWPORTS.DESKTOP;

  const tagger = useMemo(() => {
    if (!taggingEntity || !taggingAction || !taggingTrigger) {
      return {};
    }

    const context = {
      ...taggingContext,
      component: 'MultiProductCarousel',
    };

    const t = Tagger();
    return {
      ...t.entity(taggingEntity),
      ...t.action(taggingTrigger, taggingAction),
      ...t.context(context),
    };
  }, [taggingEntity, taggingContext, taggingAction, taggingTrigger]);

  return (
    <Wrapper data-elbcontext="component:multi_product_carousel" {...tagger}>
      {headline && (
        <StyledHeadline as="h1" size="three">
          {headline}
        </StyledHeadline>
      )}
      <CarouselWrapper>
        {isCarousel && (
          <CarouselButtonWrapper>
            <StyledPrevButton label="Previous" onClick={() => slideBack()} />
          </CarouselButtonWrapper>
        )}
        <ProductsContainer
          step={step}
          isCarousel={isCarousel}
          productOuterSize={productOuterSize}
          productsListOuterSize={productsListOuterSize}
        >
          {products.map(
            (
              {
                id,
                name,
                contentType,
                icon,
                title,
                link,
                taggingContext: context,
                taggingEntity: entity,
                taggingAction: action,
                taggingTrigger: trigger,
              },
              index,
            ) => (
              <Product
                id={id}
                name={name}
                contentType={contentType}
                key={index}
                icon={icon}
                title={title}
                link={link}
                taggingContext={context}
                taggingEntity={entity}
                taggingAction={action}
                taggingTrigger={trigger}
              />
            ),
          )}
        </ProductsContainer>
        {isCarousel && (
          <CarouselButtonWrapper isNext>
            <StyledNextButton onClick={() => slideForward()}>
              Next
            </StyledNextButton>
          </CarouselButtonWrapper>
        )}
      </CarouselWrapper>
    </Wrapper>
  );
}

export default MultiProductCarousel;
