import React from 'react';
import styled from '@emotion/styled';
import { css, Theme } from '@emotion/react';
import Tagger from '@elbwalker/tagger';

import { IMAGE_SIZES, PRODUCTS_SIZES } from '../../constants';

import Link from '~/shared/components/Link';
import {
  TaggingAction,
  TaggingEntity,
  TaggingTrigger,
} from '~/shared/types/walker';

const Card = styled.div(
  ({ theme }) => css`
    position: relative;
    cursor: pointer;
    width: ${PRODUCTS_SIZES.default.size}px;
    height: ${PRODUCTS_SIZES.default.size}px;
    border: ${theme.borderWidth.kilo} solid var(--cui-border-normal);
    border-radius: 24px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    margin-bottom: ${theme.spacings.byte};
    transition: all 0.1s ease-in-out;

    &:focus-within:not(:active) {
      box-shadow: 0 0 0 4px var(--cui-bg-accent);
    }
  `,
);

const CardWrapper = styled.li(
  ({ theme }) => css`
    position: relative;
    max-width: ${PRODUCTS_SIZES.default.size}px;
    cursor: pointer;

    :hover {
      > div {
        border-color: var(--cui-border-strong);
        border-width: ${theme.borderWidth.mega};
      }

      img {
        width: ${IMAGE_SIZES.hoverSize}px;
        height: ${IMAGE_SIZES.hoverSize}px;
      }

      a p {
        opacity: 1;
      }
    }

    :active {
      > div {
        background: var(--cui-bg-highlight);
      }

      img {
        width: ${IMAGE_SIZES.size}px;
        height: ${IMAGE_SIZES.size}px;
      }
    }
  `,
);

const titleStyles = ({ theme }: { theme: Theme }) => css`
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.typography.body.one.fontSize};
  line-height: ${theme.typography.body.one.lineHeight};
  text-align: center;
  transition: all 0.1s ease-in-out;
`;

const Title = styled.p(titleStyles);

const Icon = styled.img(
  ({ theme }) => `
  width: ${IMAGE_SIZES.hoverSize}px;
  height: ${IMAGE_SIZES.hoverSize}px;
  transition: all 0.1s ease-in-out;

  ${theme.mq.mega} {
    width: ${IMAGE_SIZES.size}px;
    height: ${IMAGE_SIZES.size}px;
  }
`,
);

const StyledAnchor = styled.a(
  () => `
  &:focus {
    outline: none;
  }

  &::after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
`,
);

interface ImageType {
  title: string;
  file: {
    url: string;
    details: {
      size: number;
      image: {
        width: number;
        height: number;
      };
    };
  };
}

export interface Props {
  id: string;
  name: string;
  contentType: string;
  title: string;
  icon: ImageType;
  link: string;
  taggingContext?: { [key: string]: string };
  taggingEntity?: TaggingEntity;
  taggingAction?: TaggingAction;
  taggingTrigger?: TaggingTrigger;
}

function Product({
  id,
  name,
  contentType,
  title,
  icon,
  link,
  taggingContext = {},
  taggingEntity,
  taggingAction,
  taggingTrigger,
}: Props) {
  const trackingContentEntry = {
    name,
    id,
    contentType,
  };

  const tagger = React.useMemo(() => {
    if (!taggingAction || !taggingEntity || !taggingTrigger) {
      return {};
    }
    const context = {
      ...taggingContext,
      component: 'MultiProductCarouselBox',
    };

    const t = Tagger();
    return {
      ...t.entity(taggingEntity),
      ...t.action(taggingTrigger, taggingAction),
      ...t.context(context),
    };
  }, [taggingEntity, taggingContext, taggingAction, taggingTrigger]);

  if (!icon?.file || !title) {
    return null;
  }

  return (
    <CardWrapper {...tagger}>
      <Card>
        <Icon src={icon.file.url} alt="" />
      </Card>
      <Link
        href={link}
        trackingContentEntry={trackingContentEntry}
        trackingId={''}
      >
        <StyledAnchor>
          <Title>{title}</Title>
        </StyledAnchor>
      </Link>
    </CardWrapper>
  );
}

export default Product;
